import { EventEmitter, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { EMPTY } from 'rxjs';
import { map } from 'rxjs/operators';
import { SeoService } from '../seo/seo.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  url = environment.urlApi;
  isBrowser: boolean;
  globalnavClicked = new EventEmitter();
  refreshMenus = new EventEmitter();
  menuDataRefreshed = new EventEmitter();

  constructor(@Inject(PLATFORM_ID) platformId: object,
              private http: HttpClient,
              private seo:  SeoService) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  index() {
    if (!this.isBrowser) {
      return EMPTY;
    }

    return this.http.get(`${this.url}/menus`);
  }

  /**
   * @description
   * Obtém dados de uma página de menu
   *
   * @param path
   * @param both Caso seja carregado tanto no servidor quanto browser
   */
  get(path: string, both?: boolean) {
    if (!this.isBrowser && !both) {
      return EMPTY;
    }

    return this.http.get(`${this.url}/menu`, {
      params: {
        path
      }
    })
      .pipe(
        map((res: any) => {
          this.seo.update(res.data);
          return res;
        })
      )
  }
}
