import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { EMPTY } from 'rxjs';

/**
 * @description
 * Serviço genérico de requisições
 */

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  url = environment.urlApi;
  isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) platformId: object,
              private http: HttpClient) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  get(pathname: string = '') {
    if (!this.isBrowser) {
      return EMPTY;
    }

    return this.http.get(`${this.url}/${pathname}`);
  }

  post(pathname: string = '', params: object) {
    return this.http.post(`${this.url}/${pathname}`, params);
  }
}
