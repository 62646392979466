import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RedirectService {
  url = environment.urlApi;
  isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) platformId: object,
              private http: HttpClient) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  index(uri: string) {
    if (uri.slice(-1) !== '/') {
      uri += '/';
    }

    return this.http.get(`${this.url}/redirect`, {
      params: {
        uri
      }
    });
  }
}
