export const environment = {
  production: true,
  index: true,
  urlApi: 'https://core.myralis.com.br/api',
  bugsnagKey: '327dbb0b7e597ad193b6e494bdb77a69',
  googleClientID:
    '66195095407-5rupgn98e79mnunrrdslv33puk7vcmrr.apps.googleusercontent.com',
  facebookClientID: '197097275333763',
  oneSignalRestApiKey: 'N2FlNmVkNzItYTdhMy00NGU3LWExZDEtNjY1MDkyMGVjZGEx',
  oneSignalAppId: 'd4d3acd2-69cc-4730-b9ac-1b0a41fca94e'
};
