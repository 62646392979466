import { NgModuleFactory, Type } from '@angular/core';

export const lazyWidgets: { name: string, loadChildren: () => Promise<NgModuleFactory<any> | Type<any>> }[] = [
  {
    name: 'home-content-component',
    loadChildren: () => import('../../pages/home/home-content/home-content.module').then(m => m.HomeContentModule)
  },
  {
    name: 'header-component',
    loadChildren: () => import('../../components/headers/header/header.module').then(m => m.HeaderModule)
  },
  {
    name: 'footer-component',
    loadChildren: () => import('../../components/footers/footer/footer.module').then(m => m.FooterModule)
  },
  {
    name: 'product-content-component',
    loadChildren: () => import('../../pages/product/product-content/product-content.module').then(m => m.ProductContentModule)
  },
  {
    name: 'news-single-content-component',
    loadChildren: () => import('../../pages/news-single/news-single-content/news-single-content.module').then(m => m.NewsSingleContentModule)
  },
  {
    name: 'articles-single-content-component',
    loadChildren: () => import('../../pages/articles-single/articles-single-content/articles-single-content.module').then(m => m.ArticlesSingleContentModule)
  }
];

export function lazyArrayToObj(): any {
  const result = {};
  for (const w of lazyWidgets) {
    result[w.name] = w.loadChildren;
  }
  return result;
}
