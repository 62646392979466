import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { EMPTY, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import {
  STORAGE_AUTH_INFO,
  STORAGE_USER_INFO,
} from 'src/app/config/storage-env';
import { MenuService } from '../menu/menu.service';
import { OneSignalService } from 'onesignal-ngx';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isRefreshing: boolean;
  isBrowser: boolean;
  oneSignalPlayerId = null;

  constructor(
    public http: HttpClient,
    private router: Router,
    @Inject(PLATFORM_ID) platformId: object,
    private menuService: MenuService,
    private oneSignalService: OneSignalService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    if (this.isBrowser) {
      this.oneSignalService.on('subscriptionChange', (isSubscribed) => {
        if (isSubscribed) {
          this.oneSignalService.getUserId().then(res => this.oneSignalPlayerId = res);
        }
      });

      this.oneSignalService.getUserId().then(res => this.oneSignalPlayerId = res);
    }
  }

  sign(data: any, path: string = 'login') {
    if (data.type !== 'professional') {
      data.professional_area = null;
      data.crm = null;
    }

    data.player_id = this.oneSignalPlayerId;

    return this.http.post(`${environment.urlApi}/auth/${path}`, data).pipe(
      map((res: any) => {
        const { token, user } = res.data;

        this.doLocalStore(token, user);

        return res;
      })
    );
  }

  logout() {
    if (!this.isBrowser) {
      return EMPTY;
    }

    const token = localStorage.getItem(STORAGE_AUTH_INFO);

    localStorage.removeItem(STORAGE_AUTH_INFO);
    localStorage.removeItem(STORAGE_USER_INFO);

    this.menuService.refreshMenus.emit();

    this.router.navigate(['/']);

    this.http
      .post(
        `${environment.urlApi}/auth/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
        }
      )
      .subscribe();
  }

  refresh() {
    if (this.isRefreshing) {
      return;
    }

    if (!this.isBrowser) {
      return EMPTY;
    }

    return this.http.post(`${environment.urlApi}/auth/refresh`, {}).pipe(
      map((res: any) => {
        const { token } = res.data;

        localStorage.setItem(STORAGE_AUTH_INFO, token);
      }),
      catchError((err) => {
        localStorage.removeItem(STORAGE_AUTH_INFO);
        localStorage.removeItem(STORAGE_USER_INFO);

        return throwError(err);
      }),
      finalize(() => {
        window.location.reload();
      })
    );
  }

  doLocalStore(token: string, user: any) {
    if (!this.isBrowser) {
      return EMPTY;
    }

    localStorage.setItem(STORAGE_USER_INFO, JSON.stringify(user));

    if (token) {
      localStorage.setItem(STORAGE_AUTH_INFO, token);
      this.menuService.refreshMenus.emit();

      // seta um ID externo para o usuario (ID do nosso app)
      // @ts-ignore
      if (user?.external_user_id && window?.OneSignal) {
        // @ts-ignore
        window.OneSignal.push(() => {
          // @ts-ignore
          window.OneSignal.setExternalUserId(user?.id, user?.external_user_id?.toString());
        });
      }
    }
  }

  getOneSignalPlayerId() {
    // @ts-ignore
    return window?.OneSignal?.getUserId();
  }

  isLogged() {
    if (!this.isBrowser) {
      return EMPTY;
    }

    const token = localStorage.getItem(STORAGE_AUTH_INFO);
    const user = localStorage.getItem(STORAGE_USER_INFO);

    return token && user;
  }

  getLocalInfo() {
    if (!this.isBrowser) {
      return EMPTY;
    }

    const user = localStorage.getItem(STORAGE_USER_INFO);

    return user ? JSON.parse(user) : {};
  }

  me() {
    if (!this.isBrowser) {
      return EMPTY;
    }

    return this.http.get(`${environment.urlApi}/auth/me`).pipe(
      map((res: any) => {
        const { user } = res.data;

        this.doLocalStore(null, user);

        return res;
      })
    );
  }

  forgot(email: string) {
    return this.http.post(`${environment.urlApi}/auth/forgot`, { email });
  }

  reset(data: any) {
    return this.http.post(`${environment.urlApi}/auth/reset`, data);
  }
}
