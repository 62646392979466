import { HttpErrorResponse } from '@angular/common/http';

export const handleError = (err: HttpErrorResponse): any => {
  const { errors, message } = err.error.error || err.error;
  return { fields: errors, message };
};

/**
 * @description
 * Inclui um erro da api de um campo em seu respectivo estado.
 */
export const updateFormErrors = (form: any, errors: any) => {
  if (errors.hasOwnProperty('fields')) {
    const { fields } = errors;

    if (fields) {
      for (const k in fields) {
        if (fields.hasOwnProperty(k) && form.controls[k]) {
          form.controls[k].setErrors({ custom: fields[k][0] });
        }
      }
    }
  }
};
