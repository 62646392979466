import { isPlatformBrowser } from '@angular/common';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { STORAGE_AUTH_INFO } from 'src/app/config/storage-env';
import { AuthService } from 'src/app/services/auth/auth.service';
import { handleError } from '../../utils/error';

@Injectable()
export class ErrorIntercept implements HttpInterceptor {
  isBrowser: boolean;

  constructor(
    private authService: AuthService,
    @Inject(PLATFORM_ID) platformId: object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err) => {
        if (this.isBrowser) {
          const token = localStorage.getItem(STORAGE_AUTH_INFO);

          if (err.status === 401 && token) {
            this.authService.refresh().subscribe();
          }
        }

        return throwError(handleError(err));
      })
    );
  }
}
