import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import {
  STORAGE_AUTH_INFO,
  STORAGE_USER_INFO,
} from 'src/app/config/storage-env';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) platformId: object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.handleAccess(request, next));
  }

  async handleAccess(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Promise<HttpEvent<any>> {
    if (!this.isBrowser || request.url.includes('viacep.com.br')) {
      return next.handle(request).toPromise();
    }

    const user = localStorage.getItem(STORAGE_USER_INFO);
    const token = localStorage.getItem(STORAGE_AUTH_INFO);

    if (user && token) {
      if (!request.url.includes(`logout`)) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
        });
      }
    }

    return next.handle(request).toPromise();
  }
}
