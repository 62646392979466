import { map, catchError } from 'rxjs/operators';
import { RedirectService } from './../../services/redirect/redirect.service';
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotFoundGuard implements CanActivate, CanActivateChild {
  private currentUri = '';

  constructor(private router: Router,
              private redirectService: RedirectService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const {url} = state;

    if (this.currentUri == url) {
      this.currentUri = '';
      return true;
    }

    this.currentUri = url;

    return this.redirectService.index(url)
            .pipe(
              map((res: any) => {
                let {uri} = res.data;

                if (uri.length > 1 && uri.slice(-1) === '/') {
                  uri = uri.substring(0, parseInt(uri.length) - 1);
                }

                this.router.navigateByUrl(decodeURIComponent(uri));
                return false;
              }),
              catchError(err => {
                return of(true);
              })
            );
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
}
