import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { Subscription } from 'rxjs';

import { OneSignalService } from 'onesignal-ngx';
import smoothscroll from 'smoothscroll-polyfill';

import { SeoService } from './services/seo/seo.service';
import { HttpService } from './services/http/http.service';
import { MenuService } from './services/menu/menu.service';
import { LazyLoaderService } from './services/lazy-loader/lazy-loader.service';

import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('footer', { read: ViewContainerRef, static: false }) container: ViewContainerRef;
  @ViewChild('header', { read: ViewContainerRef, static: false }) header: ViewContainerRef;
  @Input() opened: boolean;
  isBrowser: boolean;
  data: any;
  hide: boolean;
  loaded: boolean;
  refreshMenus: Subscription;

  constructor(@Inject(PLATFORM_ID) platformId: object,
              private oneSignal: OneSignalService,
              private router: Router,
              private seo: SeoService,
              private httpService: HttpService,
              private menuService: MenuService,
              private loader: LazyLoaderService) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.opened = false;
  }

  ngOnInit() {
    this.seo.updateOgUrl();

    if (this.isBrowser) {
      this.scroll();
      smoothscroll.polyfill();

      setTimeout(async () => {
        await this.oneSignal.init({
          appId: environment.oneSignalAppId,
        });
      }, 10000);
    }

    this.watchRefreshMenus();
  }

  ngOnDestroy() {
    this.refreshMenus?.unsubscribe();
  }

  ngAfterViewInit() {
    this.getMenus();
  }

  scroll() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      this.hide = evt.url === '/sitemap';

      if (this.hide) {
        this.container.clear();
        this.loaded = false;
      } else if (this.data) {
        this.load();
      }

      if (this.isBrowser) {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      }
    });
  }

  getMenus(skip = false) {
    this.menuService.index().subscribe(
      (res: any) => {
        this.data = res.data;

        if (!skip) {
          this.load();
        } else {
          this.menuService.menuDataRefreshed.emit(this.data);
        }
      }
    );
  }

  load(): void {
    if (this.isBrowser && !this.loaded) {
      this.container.clear();
      this.header.clear();

      const isDesk = window.innerWidth >= 1024;
      const delay = isDesk ? 2000 : 4000;

      setTimeout(() => {
        this.loader.load('header-component', this.container, this.data);
        document.getElementById('dd-splashscreen').remove();
      }, delay);

      setTimeout(() => this.loader.load('footer-component', this.container, this.data?.globalnav || []), delay);

      this.loaded = true;
    }
  }

  watchRefreshMenus() {
    this.refreshMenus = this.menuService.refreshMenus.subscribe(
      () => {
        this.getMenus(true);
      }
    );
  }
}
